import React from "react";


const minimizeSidebar = () => {
    const rootDiv = document.getElementById('root');
    if(rootDiv.classList.contains('sidebar-toggle-display') || rootDiv.classList.contains('sidebar-absolute')) {
        rootDiv.classList.toggle('sidebar-hidden');
    } else {
        rootDiv.classList.toggle('sidebar-icon-only');
    }      
}

const displayOffCanvasSidebar = () => {
    const sideBar = document.getElementById('sidebar');
    sideBar.classList.toggle('active');
}

const HeaderBar = ({ user }) => {

    return (
        <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">

                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                    <div className="me-3">
                        <button className="navbar-toggler align-self-center fs-3" type="button" onClick={minimizeSidebar}>
                            <span className="icon-menu"></span>
                        </button>
                    </div>
                    <div>
                        <a className="navbar-brand brand-logo" href="/">
                            <img src="/assets/img/logo/logo-mini.svg" alt="logo" />
                        </a>
                        <a className="navbar-brand brand-logo-mini" href="/">
                            <img src="/assets/img/logo/logo-mini.svg" alt="logo" />
                        </a>
                    </div>
                </div>

                <div className="navbar-menu-wrapper d-flex align-items-top">
                    <ul className="navbar-nav">
                        <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
                            <h1 className="welcome-text">Hi <span className="text-black fw-bold">{user.username}!</span></h1>
                            <h3 className="welcome-sub-text">May the dollar be with you</h3>
                        </li>
                    </ul>

                    <ul className="navbar-nav ms-auto">
                    </ul>

                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center fs-1" type="button" data-bs-toggle="offcanvas" onClick={displayOffCanvasSidebar}>
                        <span className="icon-menu"></span>
                    </button>

                </div>
            </nav>
    )
}

export default HeaderBar;