import React, { RefObject, createRef } from "react";

// Amplify
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth'
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// Components
import SideNavBar from "src/components/App/SideNavBar";
import HeaderBar from "src/components/App/HeaderBar";
import Loader from "src/components/App/Loader";
import Notifier from "src/components/App/Notifier";

// Services
import environment from './settings/environment';

// Routing
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

// Pages
const WatchList = lazy(() => import("./pages/WatchList"));
const Search = lazy(() => import("./pages/Search"));
const InstrumentPageDetails = lazy(() => import("./pages/InstrumentPageDetails"));

const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

async function getAuthToken() {
    return (await fetchAuthSession()).tokens?.idToken?.toString();
}

Amplify.configure(environment.amplifyConfig as ResourcesConfig);

function App({ signOut, user }: WithAuthenticatorProps) {

    const loaderRef: RefObject<Loader> = createRef();

    const toggleLoading = (forceClose?: boolean) => {
        loaderRef.current.toggle(forceClose);
    };

    const notifierRef: RefObject<Notifier> = createRef();

    const toggleNotifier = (message: string, type: string) => {
        notifierRef.current.toggle(message, type);
    };



    return (
        <div className="container-scroller">

            {/* Header */}
            <HeaderBar user={user} />

            {/* Main content */}
            <div className="container-fluid page-body-wrapper">
                
                {/* Sidebar */}
                <SideNavBar signOut={signOut} />

                {/* Main content */}
                <div className="main-panel">
                    <div className="content-wrapper">
                        <Loader ref={loaderRef} />
                        <Notifier ref={notifierRef} />
                        <div className="row">
                            <div className="col">
                                <Suspense fallback={<div className="container">Loading...</div>}>
                                    <Routes>
                                        <Route path="/" element={<WatchList toggleLoading={toggleLoading} />} />
                                        <Route path="/search" element={<Search toggleLoading={toggleLoading} />} />
                                        <Route path="/instrument/:instrumentId" element={<InstrumentPageDetails toggleLoading={toggleLoading} />} />
                                        <Route path="*" element={<div>Not Found</div>} />
                                    </Routes>
                                </Suspense>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default withAuthenticator(App);
