import { NavLink } from 'react-router-dom';
import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';

const SideNavBar = ({ signOut }) => {

    const location = useLocation();

    useEffect(() => {
        console.log('location pathname : ' + location.pathname)
    }, [location]);


    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">

                <li className="nav-item nav-category">Instruments</li>
                <li className={location.pathname === '/' ? "nav-item active" : "nav-item"}>
                    <NavLink className="nav-link" to="/">
                        <i className="mdi mdi-view-list menu-icon"></i>
                        <span className="menu-title">Watchlist</span>
                    </NavLink>
                </li>

                <li className={location.pathname === '/search' ? "nav-item active" : "nav-item"}>
                    <NavLink className="nav-link" to="/search">
                        <i className="mdi mdi-filter menu-icon"></i>
                        <span className="menu-title">Search</span>
                    </NavLink>
                </li>

                <li className="nav-item nav-category">User</li>
                <li className="nav-item">
                    <a className="nav-link" href="#" onClick={signOut}>
                        <i className="mdi mdi-power menu-icon"></i>
                        <span className="menu-title">Sign out</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default SideNavBar;