import React, { Component } from 'react';

type LoaderProps = {
    ref: React.RefObject<Loader>;
}

type LoaderState = {
    visible: boolean;
}

export default class Loader extends Component<LoaderProps, LoaderState> {
    
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    toggle = (forceClose:boolean = false) => {
        let newVisible = !this.state.visible;
        if (forceClose) {
            newVisible = false;
        } 

        this.setState({ visible: newVisible });
        this.render();
    }
    
    override render() {
        return (
            <>
            { this.state.visible ? (
                <div className="loader">
                    <div className="d-flex justify-content-center h-100">
                        <div className="spinner-border spinner-border-xl text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            ) : null}
            </>
        );
    }
}