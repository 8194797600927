// import { Auth } from 'aws-amplify/';

const environment = {
    production: false,
    debug: false,
    account: 'u7793609',
    dataApiKey: "tMvouGAwD481mbLltnyVc99x175DD8HJ22nM2I6c",

    amplifyConfig: {
      Auth: {
          Cognito: {
              userPoolId: 'eu-west-1_kef6L7j8h',
              userPoolClientId: '1gnfhbaarn44m7kf1nmrdah302',

              loginWith: {
                  oauth: {
                      domain: 'id.hartza.capital',
                      scopes: [
                          'email',
                          'profile',
                          'openid'
                      ],
                      redirectSignIn: ['http://localhost:3000/'],
                      redirectSignOut: ['http://localhost:3000/'],
                      responseType: 'code'
                  }
              }
          }
      },
      API: {
          REST: {
              HartzaPortfolioAPI: {
                  endpoint: 'https://api.hartza.capital/v1',
              },
              HartzaDataAPI: {
                endpoint: 'https://data.hartza.capital/v1',
            }
          }
      }
  }
}




export default environment;